<template>
  <div id="app" :class="language">
    <router-view />
    <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
    <van-popup
      v-model="isSHow"
      :close-on-click-overlay="false"
      closeable
      @click-overlay="close"
      @close="close"
      position="top"
    >
      <div class="language-title">{{ stringList.Selectyourlanguage }}</div>
      <div class="language-select">
        <div class="language-item" :class="item.value==language?'language-item-select':''" @click="changeLanguage(item.value)" v-for="(item, index) of languageList" :key="index">
          <span style="width: 100px;margin-right: 20px;text-align: left;font-size: 16px;">{{item.type}}</span>
          <span style="flex: 1;text-align: left;font-size: 14px;">{{ item.name }}</span>
          <span v-if="item.value==language"><svg-icon style="width: 16px;height: 12px;"
          iconClass="yes"
          className="icons/svg/yes.svg"
        ></svg-icon></span>
        </div>
      </div></van-popup
    >
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return { isSHow: false };
  },
  computed: {
    ...mapGetters({
      language: "language",
      languageList: "languageList",
      stringList: "stringList",
      showPopup: "showPopup",
    }),
  },
  watch: {
    language: {
      handler() {
        // console.log(document.getElementsByTagName("html"));
        document.getElementsByTagName("html")[0].classList = this.language;
        // if(this.language == "en"){
        //   document.getElementsByTagName("html")[0].append(`<style>*{ word-break: keep-all !important;}</style>`)
        // }else{

        // }
      },
      immediate: true,
    },
    showPopup: {
      handler(value) {
        this.isSHow = value;
      },
      immediate: true,
    },
  },
  methods: {
    changeLanguage(value){
      this.$store.dispatch("editLanguage", value);
    },
    close() {
      console.log("close");
      this.closeShowPopup();
    },
    ...mapActions(["closeShowPopup"]),
  },
};
</script>

<style lang="less">
#app {
  margin: auto;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.room-select {
  .ant-select-dropdown-menu {
    max-height: 150px !important;
  }
}
* {
  word-break: break-all !important;
}
.en {
  * {
    word-break: keep-all !important;
  }
}
@media screen and (min-width: 700px) {
  #app {
    width: 390px !important;
  }
  .foodModel {
    width: 390px !important;
    left: auto !important;
  }
  .van-overlay {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-popup--bottom {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-popup--top {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-sticky--fixed {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-toast--html {
    width: 300px !important;
  }
}
#app {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.language-title {
  height: 60px;
  opacity: 1;

  /* 自动布局 */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 12px 30px;
  gap: 140px;
  align-self: stretch;

  /* 纯白 */
  background: #ffffff;

  z-index: 0;
  font-family: Arial;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: 0em;

  font-variation-settings: "opsz" auto;
  color: #3d3d3d;

  z-index: 0;
}
.language-select {
  .language-item{
    height: 50px;
opacity: 1;
cursor: pointer;
/* 自动布局 */
display: flex;
flex-direction: row;
align-items: center;
padding: 16px 30px;
// gap: 140px;

/* 纯白 */
background: #FFFFFF;

z-index: 2;
  }
  .language-item-select{
    background: #F2F2F2;
    font-weight: bold;
    color: #5A578C;
  }
}

</style>
